import { BrowserModule } from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/comunes/header/header.component';
import { IconosComponent } from './components/iconos/iconos.component';
import { GescoexComponent } from './components/gescoex/gescoex.component';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { CookiesComponent } from './components/cookies/cookies.component';
import { FooterComponent } from './components/footer/footer.component';
import { AvisosComponent } from './components/avisos/avisos.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    IconosComponent,
    GescoexComponent,
    ServiciosComponent,
    ContactoComponent,
    CookiesComponent,
    FooterComponent,
    AvisosComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    AppRoutingModule
  ],
  providers: [CookiesComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
