import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute} from '@angular/router';
import { CookiesComponent } from "../cookies/cookies.component";

@Component({
  selector: 'app-avisos',
  templateUrl: './avisos.component.html'
})
export class AvisosComponent implements OnInit {

  apartado: string;
  rutaApartado: string;
  constructor(public activatedRoute:ActivatedRoute, public router: Router,public cookies: CookiesComponent) {
    
    this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd ) {
      let ruta = this.router.routerState.snapshot.root.firstChild.url[0].path;
      this.rutaApartado = ruta;
      if(ruta == 'politica-de-cookies') {
        this.apartado = 'legal_politica_de_cookies';
      }
      if(ruta == 'politica-de-privacidad') {
        this.apartado = 'legal_politica_de_privacidad';
      }
      if(ruta == 'aviso-legal') {
        this.apartado = 'legal_aviso_legal';
      }
    }

  });

  }
  gestionarCookies(){
    this.cookies.mostrar_cokies();
  }
  ngOnInit() {
  }

}
