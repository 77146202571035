import { Component, OnInit } from '@angular/core';
import { DatosService } from "../../services/datos.service";


@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html'
})
export class CookiesComponent implements OnInit {

  constructor(public _datosService:DatosService) { }
 
  public isCookies:boolean = false;
  public gestionarCookies:boolean = false;
  public modal:any;

  ngOnInit() {
    if(this.getCookie('isCookies') != null){
      this.isCookies = true;
    };
    if(this.isCookies){
      this.ocultar_ventana_cookies();
    }
    else{
      this.mostrar_cokies();
    }
  }
  getCookie(name: string) {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
    }
    else
    {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
        end = dc.length;
        }
    }
    return decodeURI(dc.substring(begin + prefix.length, end));
} 
setCookie(name:string,value: any,days: number) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
mostrar_cokies(){
  console.log("mostra cokies");
  this.isCookies = false;
  document.getElementsByClassName('cookies')[0].removeAttribute("hidden");
  document.body.style.setProperty('overflow','hidden');
  document.body.style.setProperty('max-width','100vw');
  document.body.style.setProperty('max-height','100vh');

}
ocultar_ventana_cookies(){
    this.isCookies = true;
    document.getElementsByClassName('cookies')[0].setAttribute("hidden",'true');
    document.body.style.setProperty('overflow','');
    document.body.style.setProperty('max-width','');
    document.body.style.setProperty('max-height','');
}
aceptaCookies() {
  this.isCookies = true;
  this.ocultar_ventana_cookies();
  this.setCookie("isCookies",1,365);
} 
}
