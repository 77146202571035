import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iconos',
  templateUrl: './iconos.component.html'
})
export class IconosComponent implements OnInit {

  servicios:any = ['aduanas', 'tecnicos', 'almacenaje', 'transporte'];

  constructor() { }

  ngOnInit() {
  }

}
