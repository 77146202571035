import { Component,  OnInit, ViewChild, Input } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { DatosService } from '../app/services/datos.service';

//Por default dejamos el español
let defaultLanguage = "es";
//Y como adicional el resto
const additionalLanguages = ["en", "fr"];
//Combinamos las traducciones disponibles al default.
const languages: string[] = [defaultLanguage].concat(additionalLanguages);



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'gescoex';
    public isCookies:boolean = false;
  constructor(public _datosService:DatosService, private translate: TranslateService, private router: Router) {  }

    ngOnInit(): void {
         //Asignamos el lenguaje predefinido y los disponibles
         this.translate.setDefaultLang(defaultLanguage);
         this.translate.addLangs(additionalLanguages);
         //Nos basamos en el lenguaje del navegador para cambiar el lenguaje
         let initLang = this.translate.getBrowserLang();
         if (languages.indexOf(initLang) === -1) {
             initLang = defaultLanguage;

         }
         //En base a lo anterior asignamos el lenguaje a usar
         this.translate.use(initLang);

         console.log(defaultLanguage);
         this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });

    }



}
