import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { CookiesComponent } from '../../cookies/cookies.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  apartados:any = ['home', 'gescoex', 'servicios', 'contacto'];
  avisos:any = ['aviso-legal', 'politica-de-privacidad', 'politica-de-cookies'];
  menuOpen = false;
  constructor(private translate: TranslateService,public cookies: CookiesComponent) { }

  ngOnInit() {
  }

  gestionarCookies(){
    this.cookies.mostrar_cokies();
  }
  changeMenu() {
    this.menuOpen =! this.menuOpen;
  }

}
