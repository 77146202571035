import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { HomeComponent} from '../app/components/home/home.component';
import { GescoexComponent} from '../app/components/gescoex/gescoex.component';
import { ServiciosComponent} from '../app/components/servicios/servicios.component';
import { ContactoComponent} from '../app/components/contacto/contacto.component';
import { AvisosComponent} from '../app/components/avisos/avisos.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'gescoex', component: GescoexComponent },
  { path: 'servicios', component: ServiciosComponent },
  { path: 'contacto', component: ContactoComponent },
  { path: 'servicios/:id', component: ServiciosComponent },
  { path: 'aviso-legal', component: AvisosComponent },
  { path: 'politica-de-privacidad', component: AvisosComponent },
  { path: 'politica-de-cookies', component: AvisosComponent },
  { path: '**', pathMatch: 'full', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
